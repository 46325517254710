import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import Img from "gatsby-image";
import SEO from "../components/seo";

const ProjectsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      projects: allProjectsYaml {
        edges {
          node {
            title
            abstract
            description
            slug
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Projects |" />
      <section className="projects-section">
        <h1>Useless is not worthless</h1>
        <h3>
          Here's a collection of side projects. Most of them don't have any
          other purpose than exploring the field of human-computer interaction
          using web technologies.
        </h3>
      </section>
      <section className="projects">
        {data.projects.edges.map(({ node }, id) => (
          <div key={node.title} className="project">
            <Img fluid={node.image.childImageSharp.fluid} alt={node.alt} />
            <p>{node.abstract}</p>
            <section
              style={{
                zIndex: 10,
                position: "relative",
                width: `fit-content`,
                fontSize: `1em`,
              }}
            >
              <Link
                to={`/project/${node.slug}`}
                state={{ title: node.title }}
                className="project-link"
              >
                {node.title}
              </Link>
            </section>
          </div>
        ))}
      </section>
    </Layout>
  );
};

export default ProjectsPage;
